<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
    <h2 class="item-page-title-field mr-auto">
        {{'series.page.titleprefix' | translate}}<ds-metadata-values
            [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
    </h2>
    <div class="pl-2">
        <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object"
            [tooltipMsg]="'series.page.edit'"></ds-dso-page-edit-button>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-md-4">
        <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
            <ds-thumbnail [thumbnail]="object?.thumbnail | async"
                [defaultImage]="'assets/images/series-placeholder.svg'"></ds-thumbnail>
        </ds-metadata-field-wrapper>
    </div>
    <div class="col-xs-12 col-md-6">
        <h5 class="simple-view-element-header ng-star-inserted">Abstract</h5>
        <ds-truncatable [id]="object.id" *ngIf="object.firstMetadataValue('dc.description.abstract')"
            class="item-list-abstract">
            <ds-truncatable-part [id]="object.id" [minLines]="3"><span
                    [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
            </ds-truncatable-part>
        </ds-truncatable>

        <ds-generic-item-page-field [item]="object" [fields]="['dc.description']" [label]="'series.page.description'">
        </ds-generic-item-page-field>

        <ds-generic-item-page-field [item]="object" [fields]="['dc.subject']" [separator]="','"
            [label]="'item.page.subject'">
        </ds-generic-item-page-field>
        <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.doi']" [label]="'item.page.doi'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.uri']" [label]="'item.page.uri'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [item]="object" [fields]="['dc.source.uri']" [label]="'item.source.page.uri'">
        </ds-item-page-uri-field>
        <div>
            <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
                {{"item.page.link.full" | translate}}
            </a>
        </div>
    </div>
    <div class="mt-5 w-100">
        <ds-tabbed-related-entities-search [item]="object" [relationTypes]="[
                                        {
                                          label: 'isSeriesOfPublication',
                                          filter: 'isSeriesOfPublication',
                                          configuration: 'publicationdateissued'
                                        }
                                        ]">
        </ds-tabbed-related-entities-search>
    </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>